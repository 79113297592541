<template>
    <!-- 摄影订单详情路由 -->
    <div class="order_photography_box">
        <!-- 用户信息标题 -->
        <div class="order_photography_title">用户信息</div>
        <!-- 用户信息 -->
        <div class="order_photography_user_info">
            <span>夏沫</span>
            <span>13720000000</span>
        </div>
        <!-- 拍摄时间标题 -->
        <div class="order_photography_title">拍摄时间</div>
        <!-- 拍摄时间 -->
        <div class="order_photography_time_box">
            <!-- 开始时间 -->
            <div class="order_photography_time">
                <!-- 日期 -->
                <div class="order_photography_time_date">11-19</div>
                <!-- 时间 -->
                <div class="order_photography_time_week">
                    <!-- 周 -->
                    <span>周二</span>
                    <!-- 时间 -->
                    <span>09:00</span>
                </div>
            </div>
            <!-- 分割线 -->
            <hr style="width: 100px;display: inline-block;vertical-align:middle;margin: 20px">
            <!-- 结束时间 -->
            <div class="order_photography_time">
                <!-- 日期 -->
                <div class="order_photography_time_date">11-19</div>
                <!-- 时间 -->
                <div class="order_photography_time_week">
                    <!-- 周 -->
                    <span>周二</span>
                    <!-- 时间 -->
                    <span>09:00</span>
                </div>
            </div>
        </div>
        <!-- 订单详情标题 -->
        <div class="order_photography_title">订单详情</div>
        <!-- 订单详情 -->
        <div class="order_photography_detail_box">
            <el-row>
                <!-- 标题列 -->
                <el-col :span="12">
                    <div>主题</div>
                </el-col>
                <!-- 对应内容 -->
                <el-col :span="12">
                    <div class="order_photography_detail_rt">婚纱拍摄</div>
                </el-col>
            </el-row>
            <el-row>
                <!-- 标题列 -->
                <el-col :span="12">
                    <div>风格</div>
                </el-col>
                <!-- 对应内容 -->
                <el-col :span="12">
                    <div class="order_photography_detail_rt">简约</div>
                </el-col>
            </el-row>
            <el-row>
                <!-- 标题列 -->
                <el-col :span="12">
                    <div>摄影师</div>
                </el-col>
                <!-- 对应内容 -->
                <el-col :span="12">
                    <div class="order_photography_detail_rt">x1</div>
                </el-col>
            </el-row>
            <el-row>
                <!-- 标题列 -->
                <el-col :span="12">
                    <div>化妆师</div>
                </el-col>
                <!-- 对应内容 -->
                <el-col :span="12">
                    <div class="order_photography_detail_rt">x1</div>
                </el-col>
            </el-row>
            <el-row>
                <!-- 标题列 -->
                <el-col :span="12">
                    <div>胶卷</div>
                </el-col>
                <!-- 对应内容 -->
                <el-col :span="12">
                    <div class="order_photography_detail_rt">胶卷1</div>
                </el-col>
            </el-row>
            <el-row>
                <!-- 标题列 -->
                <el-col :span="12">
                    <div>场地</div>
                </el-col>
                <!-- 对应内容 -->
                <el-col :span="12">
                    <div class="order_photography_detail_rt">北理工校区</div>
                </el-col>
            </el-row>
            <el-row>
                <!-- 标题列 -->
                <el-col :span="12">
                    <div>拍摄费用</div>
                </el-col>
                <!-- 对应内容 -->
                <el-col :span="12">
                    <div class="order_photography_detail_rt">
                        <span class="order_photography_detail_rt_price">¥599.00</span>
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <!-- 标题列 -->
                <el-col :span="12">
                    <div>场地费用</div>
                </el-col>
                <!-- 对应内容 -->
                <el-col :span="12">
                    <div class="order_photography_detail_rt">
                        <span>x3</span>
                        <span class="order_photography_detail_rt_price">¥30.00</span>
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <!-- 标题列 -->
                <el-col :span="12">
                    <div>化妆费用</div>
                </el-col>
                <!-- 对应内容 -->
                <el-col :span="12">
                    <div class="order_photography_detail_rt">
                        <span class="order_photography_detail_rt_price">¥200.00</span>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 结算 -->
        <div class="order_photography_total_box">
            <!-- 标题 -->
            <span>订单总额：</span>
            <!-- 价格 -->
            <span>¥689.00</span>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
/* element-ui */
.order_photography_detail_box>.el-row{
    margin-bottom: 10px;
}
.order_photography_detail_box>.el-row:last-child{
    margin-bottom: 0;
}

/* 租赁订单盒子 */
.order_photography_box{
    border: 1px solid #C0C4CC;
}
/* 标题 */
.order_photography_title{
    padding: 15px 20px;
    font-size: 17px;
    background-color: #E4E7ED;
}

/* 用户信息 */
.order_photography_user_info{
    padding: 15px 20px;
}
.order_photography_user_info>span + span{
    margin-left: 10px;
}

/* 时间盒子 */
.order_photography_time_box{
    padding: 15px 20px;
}
/* 时间 */
.order_photography_time{
    display: inline-block;
    vertical-align: middle;
}
/* 日期 */
.order_photography_time_date{
    font-size: 25px;
    font-weight: bold;
}
/* 星期 */
.order_photography_time_week{
    margin-top: 5px;
}
.order_photography_time_week>span + span{
    margin-left: 10px;
}

/* 订单详情 */
.order_photography_detail_box{
    padding: 15px 20px;
}
/* 订单详情右侧 */
.order_photography_detail_rt{
    text-align: right;
}
/* 价格颜色 */
.order_photography_detail_rt_price{
    margin-left: 10px;
    font-size: 17px;
    font-weight: bold;
    color: #F56C6C;
}

/* 结算 */
.order_photography_total_box{
    background-color: #C0C4CC;
    padding: 15px 20px;
    text-align: right;
}
.order_photography_total_box>span + span{
    margin-left: 15px;
    font-size: 25px;
    font-weight: bold;
    color: red;
}
</style>
